import { Observable } from 'lib0/observable';

let trackedImports = null;
let runDry = false;

globalThis.__dynamicImportMiddleWare = new (class DynamicImportMiddleWare extends Observable {

	constructor () {
		
		super();

		this.cache = new Map();
		this.maxRetries = 5;

	}

	// this'll track and return all import() calls ran inside the `fn` argument
	trackImports = (fn, options = {}) => {

		if(options.runDry) {
			runDry = true;
		}

		trackedImports = [];

		fn();

		const result = [...trackedImports];

		trackedImports = null;
		runDry = false;

		return result;
	}

	import = async moduleName => {

		if(trackedImports && !trackedImports.includes(moduleName)) {
			trackedImports.push(moduleName);
		}

		if(runDry) {
			return;
		}

		if(this.cache.has(moduleName)) {
			// module was previously loaded and we have stored the result
			return this.cache.get(moduleName)
		}

		try {

			// first run a regular import
			return await this.#runImport(moduleName);

		} catch(e) {

			// something went wrong. Start retrying
			for (let i = 0; i < this.maxRetries; i++) {

				try {

					return await this.#runImport(moduleName, {
						failedBefore: true,
						attempts: i + 1
					});

				} catch(e) {

					// Retry attempt failed. Wait one second
					// and try again.
					await new Promise(resolve => 
						setTimeout(resolve, 1000)
					);

				}

			}

			this.emit('module-load-failed', [moduleName, e]);

			// retrying failed. Give up and throw the original error
			throw e;

		}

	}

	#runImport = async (moduleName, options = {}) => {

		// run native import
		const result = await nativeImport(
			// if not running for the first time, add a cache break to force
			// the browser to re-fetch the module
			options.failedBefore ? `${moduleName}?t=${+new Date()}` : moduleName 
		);

		// cache the successful result
		this.cache.set(moduleName, result);

		// emit an event
		this.emit('module-load-success', [moduleName, options]);

		// return it
		return result;

	}

});


// define a global `import(` alternative that is the same length 
// so we don't mess up source map indexes
globalThis.__mprt = globalThis.__dynamicImportMiddleWare.import;